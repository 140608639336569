export const getSpeakerByInitials = (initials) => {
  const speaker = speakers.find((speaker) => speaker.initials === initials);

  if (!speaker) {
    console.error("No speaker with initials:", initials, "found");
    return {};
  }

  return speaker;
};

const speakers = [
  /*{
    name: "Alexander Lacik",
    title: "Chief Executive Officer (CEO)",
    department: "",
    short_description:
      "Alexander Lacik has been CEO of Pandora since April 2019. He is a member of the Executive Management and Executive Leadership Team (ELT).",
    description: `Alexander has more than 25 years of leadership experience from large global consumer goods companies. Before joining Pandora, he was the CEO at childcare company Britax. Prior to that, he headed Reckitt Benckiser’s North American business and held international leadership positions at Procter & Gamble.
    <br />
    <br />
    He has worked in the US, Europe and Scandinavia and led strategic international expansions delivering strong revenue gains.
    <br />
    <br />
    Alexander is Swedish and has a Bachelor in Business Administration from Växjo University, Sweden.
    `,
    image:
      "https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4370Alexander_Lacik_-_Chief_Executive_Officer.png",
  },*/
  {
    name: "Luciano Rodembusch",
    title: "General Manager",
    department: "North America",
    short_description:
      "Luciano Rodembusch is a passionate business leader with a wealth of experience in growing global brands and more than 10 years of experience in international leadership roles. Luciano joined Pandora in August of 2021 and serves as the General Manager of Pandora's NAM Cluster, overseeing strategy and all aspects of the market business which accounts for approximately 30% of revenue.  Reporting to the CCO of Pandora, Luciano leads the largest cluster for Pandora.   ",
    description: `Luciano Rodembusch is a passionate business leader with a wealth of experience in growing global brands and more than 10 years of experience in international leadership roles. Luciano joined Pandora in August of 2021 and serves as the General Manager of Pandora's NAM Cluster, overseeing strategy and all aspects of the market business which accounts for approximately 30% of revenue.  Reporting to the CCO of Pandora, Luciano leads the largest cluster for Pandora.   
    <br />
    <br />
    Most recently, Luciano served as SVP Americas at Tiffany & Co and has held positions in marketing, finance, and sales over his career.  
    <br />
    <br />  
    Luciano has a bachelor degree from Pontifícia Universidade Católica do Rio Grande do Sul and an MBA from Fundação Dom Cabral. He serves on the board of Catholic Guardian Services in New York.  
    <br />
    <br />  
    Luciano Rodembusch is Brazilian. He lives in New York with his wife and two children.  
    `,
    image:
      "https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4376Luciano_Rodembusch_-_General_Manager_NAM.png",
  },
  {
    name: "Martino Pessina",
    title: "Chief Commercial Officer (CCO)",
    department: "",
    short_description:
      "Martino Pessina has been the Chief Commercial Officer of Pandora since March 2020. He is a is member of the Executive Leadership Team (ELT).",
    description: `Martino Pessina has been the Chief Commercial Officer of Pandora since March 2020. He is a is member of the Executive Leadership Team (ELT).
    <br />
    <br />
    Martino joined Pandora from a position as President of H&M North America. He has more than 18 years’ experience within international fashion retail, including global P&L management, omni operations, global merchandising and global expansion management. Martino has applied his skills during two major turnarounds in Europe and North America.
    <br />
    <br />
    Martino is responsible for Pandora’s commercial organization including merchandising, retail excellence, store design planning & execution, and network & franchise management.
    <br />
    <br />
    Martino is Italian and has a Master of Science in Business Administration from Bocconi University in Milan.
    `,
    image:
      "https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4377Martino_Pessina_-_Chief_Commerical_Officer.png",
  },
  {
    name: "Cyrus Erickson",
    title: "Vice President",
    department: "Merchandising",
    short_description:
      "Cyrus Erickson is a professional with vast knowledge of the retail landscape and product development spanning 24+ years.  He is a leader and innovator and understands the importance of collaborative partnerships across all areas of an organization. ",
    description: `Cyrus Erickson is a professional with vast knowledge of the retail landscape and product development spanning 24+ years.  He is a leader and innovator and understands the importance of collaborative partnerships across all areas of an organization. 
    <br />
    <br />
    Reporting to the President of Pandora’s NAM cluster, and a member of the NAM Executive Leadership Team, Cyrus has served in his current role since June 2019, and has oversight for product merchandising, merchandise planning, and demand planning. In working with his cross functional partners, Cyrus returned Pandora to better inventory health, amplified our Pandora Rose metal business, and re-assorted the price point product mix to appeal to a broader customer base.
    <br />
    <br />
    Prior to joining Pandora, Cyrus held leadership roles at Calvin Klein, ARMANI, and American Eagle Outfitters. He grew up in department store retailing with experiences in buying, merchandising and product development.  
    <br />
    <br />
    Cyrus earned his bachelor’s degree from Penn State University. 
    <br />
    <br />
    Cyrus has served on several nonprofit boards including City Theatre and Steel Valley Trail Council. Currently, he is an active volunteer for the Alzheimer’s Association / Caring Kind, Venture Outdoors, and Back to Blue.
     
    `,
    image:
      "https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4371Cyrus_Erickson_-_VP_Merchandising.png",
  },
  {
    name: "Detria Courtalis",
    title: "Vice President",
    department: "Wholesale Sales, US",
    short_description:
      "Detria Courtalis is a seasoned sales leader with over 30 years of experience in business development, retail management, account management, and organizational leadership. She is a professional with exceptional strategic vision and a drive for the development of people. ",
    description: `Detria Courtalis is a seasoned sales leader with over 30 years of experience in business development, retail management, account management, and organizational leadership. She is a professional with exceptional strategic vision and a drive for the development of people. 
    <br />
    <br />
    Reporting to the President of Pandora’s NAM cluster and a member of the NAM Executive Leadership Team, Detria has full responsible for US wholesale and franchise business locations, sales, strategy, operations, and real estate. Detria’s role is to lead the collaboration and execution across these functions to deliver on commitments defined in Pandora’s global strategy. 
    <br />
    <br />
    Detria joined Pandora in October of 2011, and during her time with Pandora, she has served in various roles including Training Manager, Retail & Franchise Director, and most recently, Director of Sales, Eastern US.
    <br />
    <br />
    Prior to Pandora, Detria held management roles with The Body Shop, Victoria’s Secret, and Limited Brands. She also held the role of VP of Operations for Celebree Learning Centers. 
    <br />
    <br />
    Detria holds a bachelor’s degree from the University of Mount Union and a CEG, Coaching & Development certification from Coach University. 
    <br />
    <br />
    Currently, Detria serves as a board member for the Ed Block Courage Award Foundation.   
    
     
    `,
    image:
      "https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4372Detria_Courtalis_-_VP_Sales.png",
  },
  {
    name: "Jacqueline Spencer",
    title: "Vice President",
    department: "Retail Sales, US",
    short_description:
      "Jacqueline (Jackie) Spencer is a seasoned leader with 27 years of experience in retail, customer service, and organizational leadership. She has exceptional business acumen and drive for ensuring that an organization’s vision, mission, and strategic goals are translated and achieved by the retail business. ",
    description: `Jacqueline (Jackie) Spencer is a seasoned leader with 27 years of experience in retail, customer service, and organizational leadership. She has exceptional business acumen and drive for ensuring that an organization’s vision, mission, and strategic goals are translated and achieved by the retail business. 
    <br />
    <br />
    Jackie has served in her current role with Pandora’s US market since December 2017, and has oversight for Pandora’s Owned & Operated stores in the U.S. 
    <br />
    <br />
    Reporting to the President of Pandora’s NAM cluster and a member of the NAM Executive Leadership Team, Jackie’s partnership with organizational leaders is instrumental in identifying and maximizing market opportunities and developing best in class retail operation practices and procedures for long-term success. She is building a world-class retail business in the U.S. market through strategic direction.  
    <br />
    <br />
    Prior to joining Pandora, Jackie served as VP, Outlet Division for Michael Kors, and held several additional leadership roles during her eight-year tenure. Prior to Michal Kors, she held leadership roles with J Crew (14 years), as well as Jones New York (three years). Jackie also served in the Royal Navy for 8.5 years, primarily in Human Resources and training roles. During her tenure, she was employed in three countries, served on a search and rescue squadron, and was part of the North Atlantic Treaty Organization (NATO) in Italy.
    
     
    `,
    image:
      "https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4373Jackie_Spencer_-_VP_Retail_Sales.png",
  },
  {
    name: "Kamanasish Kundu",
    title: "Director",
    department: "e-Commerce, Pandora Americas",
    short_description:
      "Kamanasish Kundu “KK” is classically trained in project management and is an accomplished, results driven leader with MBA, PMP®, PMI-ACP, and Google AdWords certification. He is a seasoned professional with 12+ years of experience managing and directing cross functional teams in the e-Commerce, retail, and engineering sectors with vast experience with e-Commerce enterprise systems, analytics, and digital marketing.",
    description: `Kamanasish Kundu “KK” is classically trained in project management and is an accomplished, results driven leader with MBA, PMP®, PMI-ACP, and Google AdWords certification. He is a seasoned professional with 12+ years of experience managing and directing cross functional teams in the e-Commerce, retail, and engineering sectors with vast experience with e-Commerce enterprise systems, analytics, and digital marketing.
    <br />
    <br />
    Reporting to the President of Pandora’s NAM cluster, the Chief Digital Officer in Global, and a member of the NAM Executive Leadership Team, KK has oversight for all aspects of the e-Commerce business including P&L, delivering trading excellence and offering a best in class connected omnichannel experience. 
    <br />
    <br />
    KK joined Pandora in October of 2015 and during his time with Pandora, he has held an important role as the first team member for the expansion of the e-Commerce journey, being a consummate member in the development of Pandora’s US e-Commerce platform. He is recognized for his reliable and consistent ability to drive change, process improvement, and innovation while delivering results and keeping the customer in at the center of developments and enhancements. 
    <br />
    <br />
    Prior to Pandora, KK held the Lead Engineer role with Siemens in Germany.
    <br />
    <br />
    KK holds an MBA from Hult International Business School, a Strategy Program certification from Harvard University, and a bachelor’s degree from Jadavpur University in India, 
    <br />
    <br />
    Currently, KK volunteers as a Director at Project Management Institute (PMI Baltimore Chapter). 
     
    `,
    image:
      "https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4375Kamansish_Kundu_-_Director_E-Commerce.png",
  },
  {
    name: "Scot Johnson",
    title: "Vice President",
    department: "Human Resources",
    short_description:
      "Scot Johnson is a seasoned Human Resources leader with 15+ years of progressive experience in the retail, real estate management, and not for profit sectors. Scot is highly experienced in strategic human resource functions, organizational and succession development, workflow process improvement, staff productivity, and employee engagement. He is adept in change initiatives that obtain positive results through situational leadership and cohesive team building. ",
    description: `Scot Johnson is a seasoned Human Resources leader with 15+ years of progressive experience in the retail, real estate management, and not for profit sectors. Scot is highly experienced in strategic human resource functions, organizational and succession development, workflow process improvement, staff productivity, and employee engagement. He is adept in change initiatives that obtain positive results through situational leadership and cohesive team building. 
    <br />
    <br />
    Reporting to the President of Pandora’s NAM cluster, and a member of the NAM Executive Leadership Team, Scot has served in his role since January 2019, and has oversight for all people-related initiatives supporting this expansive retail organization, with operations in the United States and Canada. 
    <br />
    <br />
    Prior to joining Pandora, Scot served as VP, Human Resources for Aspen Heights Partners, a real estate development and management company. Scot has also held leadership roles with SportClips Haircuts, Wood Group Mustang, Lower Colorado River Authority, and the America Heart Association.  
    <br />
    <br />
    Scot earned his bachelor’s degree from Texas State University and his master’s degree of Human Resources, Organizational Development from The University of Texas at Austin.  
     
    `,
    image:
      "https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4378Scot_Johnson_-_VP_HR.png",
  },
  {
    name: "Jennifer Glass",
    title: "Interim Vice-President",
    department: "Marketing",
    short_description:
      "Jennifer Glass is a seasoned senior marketing executive with extensive experience in digital strategy, analytics, and CRM.  She has worked for almost two decades in the marketing space – supporting Fortune 100 and 500 companies across the retail, technology and financial sectors. She has a proven track record of driving innovation and growth strategies, managing large, full-funnel media budgets, and expanding market share with leading global enterprises on both the agency and client side.",
    description: `Jennifer Glass is a seasoned senior marketing executive with extensive experience in digital strategy, analytics, and CRM.  She has worked for almost two decades in the marketing space – supporting Fortune 100 and 500 companies across the retail, technology and financial sectors. She has a proven track record of driving innovation and growth strategies, managing large, full-funnel media budgets, and expanding market share with leading global enterprises on both the agency and client side.
    <br />
    <br />
    In her current role as Interim Vice President of Marketing for Pandora’s North America market, and serving as a member of the Executive Leadership Team, Jennifer oversees a team of marketing experts and has the responsibility of all aspects of the consumer journey including go-to-market, earned media, owned & paid digital marketing and retail marketing & creative services. 
    <br />
    <br />
    Previously, Jennifer oversaw growth and consumer insights for Pandora North America as Director of Digital, as well as the data and CRM strategies for the Pandora Americas region. 
    <br />
    <br />
    Prior to joining Pandora, Jennifer served as the Director of Client Services for global marketing agency Jellyfish, working as a partner to leading companies including Google, Uber, Slack and PVH. Jennifer also has extensive marketing experience in academia and financial services. 
    <br />
    <br />
    Jennifer holds a B.S. in Business and Marketing from Towson University. 
    `,
    image:
      "https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4374Jennifer_Glass_-_Interim_VP_Marketing.png",
  },
  {
    name: "Elise Smith",
    title: "Global Director, Learning & Capability",
    short_description: `Elise Smith
    With a background in retail sales, operations, training, and leadership, Elise joined Pandora in 2015 as an instructional designer and has held various roles in Learning Design, Training Delivery, Talent.`,
    description: `Elise Smith
    With a background in retail sales, operations, training, and leadership, Elise joined Pandora in 2015 as an instructional designer and has held various roles in Learning Design, Training Delivery, Talent.  Most recently, Elise has taken on a new role as Global Director, Learning and Capability. Elise will drive the Pandora global philosophy, strategy, policies, frameworks and LMS platforms of learning and capability for all employees across office, retail, and manufacturing populations. She will manage learning platforms and products, and will co-lead with the business the activation of global ‘Faculties and Academies’ to support capability-building linked to the Phoenix strategy.`,
    image:
      "https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4503EliseSmith_e1ev2uo.jpg",
  },
];

export default speakers;
