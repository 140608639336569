<template>
	<div>
		<v-card color="transparent" tile flat class="white--text">
			<div class="d-flex align-center" style="gap: 1rem">
				<v-img
					class="rounded-circle"
					width="100px"
					height="100px"
					:src="speaker.image"
				></v-img>
				<div style="position: relative; margin-top: 32px; width: 100%">
					<div style="position: absolute; top: 0; left: 0">
						<p class="mb-0">
							{{ speaker.title
							}}<span v-if="speaker.department"
								>, {{ speaker.department }}</span
							>
						</p>

						<p class="font-weight-bold">
							{{ speaker.name }}
						</p>
					</div>
				</div>
			</div>

			<v-row style="padding-top: 56px">
				<v-col>
					<p
						style="max-height: 400px"
						:style="{ 'overflow-y': expanded ? 'auto' : null }"
						:class="{ 'line-clamp': !expanded }"
					>
						{{ speaker.short_description }}
					</p>

					<div class="d-flex justify-center">
						<v-btn
							large
							class="button--dialog__activator"
							@click="dialog = !dialog"
							style="width: 50%"
							>Read more
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-card>

		<!-- Dialog -->
		<v-dialog
			v-model="dialog"
			hide-overlay
			origin="right center"
			content-class="rounded-lg"
			max-width="430px"
			attach="#speaker-wrapper"
		>
			<v-card color="transparent" class="card--dialog">
				<v-card-title class="d-flex justify-end align-center"
					><v-btn icon color="white" @click="dialog = false"
						><v-icon>fas fa-times</v-icon></v-btn
					></v-card-title
				>
				<v-card-text
					class="white--text d-flex flex-column align-center"
					style="gap: 1rem"
				>
					<v-img
						class="rounded-circle"
						width="100px"
						height="100px"
						:src="speaker.image"
					></v-img>
					<div>
						<p class="mb-0 text-center">
							{{ speaker.title }}, {{ speaker.department }}
						</p>
						<h2 class="text-center">{{ speaker.name }}</h2>
					</div>

					<p
						style="
							max-height: 400px;
							overflow-y: auto;
							text-align: justify;
							text-justify: inter-word;
						"
						class="black--text mt-4 px-6"
						v-html="speaker.description"
					></p>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: "SpeakerCard",

	props: {
		speaker: {
			type: Object,
		},
	},

	data() {
		return {
			expanded: false,
			dialog: false,
		};
	},
};
</script>

<style lang="scss">
.line-clamp {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.card--dialog {
	background: transparent linear-gradient(0deg, #f3eee7 0%, #a78e8d 100%) 0%
		0% no-repeat padding-box;
	box-shadow: inset 0px -6px 20px #5c403f, 0px 3px 6px #5c403f;
	border-radius: 13px;
	opacity: 1;
	backdrop-filter: blur(50px);
}

.button--dialog__activator {
	background: #f4eee7 0% 0% no-repeat padding-box;
	box-shadow: inset 0px -6px 20px #5c403f, 0px 3px 6px #5c403f;
	border-radius: 5px;
}

@media screen and (min-width: 1904px) {
	.livehouse-app.v-application .v-dialog__content {
		justify-content: flex-end;
		padding-right: 3vw;
	}
}
</style>
