<template>
	<div>
		<v-form @submit.prevent="submit">
			<v-container fluid align="center" class="px-0">
				<v-row v-if="success">
					<v-col class="pb-0">
						<span class="black--text">Your question was sent!</span>
					</v-col>
				</v-row>
				<v-row class="pt-0">
					<v-col
						cols="12"
						md="6"
						class="d-flex align-center pt-0"
						style="gap: 1rem"
					>
						<v-text-field
							v-model="question"
							solo
							dense
							label="Ask Question"
							placeholder="Your Question..."
							hide-details="auto"
						>
							<template #append>
								<v-btn
									icon
									color="#5C403F"
									type="submit"
									:disabled="!question"
									><v-icon>far fa-paper-plane</v-icon></v-btn
								>
							</template>
						</v-text-field>

						<div class="d-inline-flex align-center">
							<v-checkbox v-model="incognito" dark></v-checkbox>
							<p class="mb-0 white--text font-weight-bold">
								Send as Incognito
							</p>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
	props: {
		event: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			question: "",
			success: false,
			incognito: false,
		};
	},

	computed: {
		...mapGetters(["me"]),
		requestData() {
			return {
				name: this.incognito ? "Anonymous" : `${this.me.name}`,
				email: this.incognito ? "Anonymous" : this.me.username,
				question: this.question,
			};
		},
		url() {
			return `https://streams.eventcdn.net/id/${this.event.eventcdn.event_id}/qa/${this.event.eventcdn.ia_token}`;
		},
	},

	methods: {
		async submit() {
			const url = this.url;

			const data = this.requestData;

			console.log("Data:", data);

			await axios.post(url, data);

			this.success = true;

			setTimeout(() => (this.success = false), 5000);

			this.question = "";
		},
	},
};
</script>

<style>
</style>
