<template>
	<Page
		:background-color="event.pages.Home.backgroundColor"
		poster="https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4309pandora-background.png"
		v-if="event && event.eventcdn"
	>
		<v-container fluid class="px-4 px-md-8">
			<v-row>
				<v-col>
					<div
						class="d-inline-flex align-end white--text"
						style="gap: 1rem"
					>
						<v-img
							max-width="60px"
							contain
							src="https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4319Pandora_Monogram2x.png"
						></v-img>
						<div class="d-inline-block">
							<p class="mb-n2">JANUARY 24, 2022, 12PM EST</p>
							<h1
								class="mb-0 pb-0"
								style="text-shadow: 0px 3px 6px #5c403f"
							>
								PANDORA STATE OF MIND
							</h1>
						</div>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col
					cols="12"
					xl="9"
					class="pt-0"
					id="speaker-wrapper"
					style="position: relative"
				>
					<div ref="contentWrapper">
						<v-responsive
							:aspect-ratio="16 / 9"
							v-resize="updateSpeakersMaxHeight"
						>
							<!-- Waiting -->
							<template v-if="event.eventcdn.state === 'WAITING'">
								<div
									style="
										position: relative;
										height: 103%;
										width: 100%;
										margin-top: -1%;
									"
									class="
										d-flex
										flex-column
										align-center
										justify-center
									"
								>
									<video
										autoplay
										muted
										loop
										src="https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4328countdown_background.mp4"
										style="
											position: absolute;
											height: 100%;
											width: 100%;
											object-fit: cover;
										"
									></video>
									<Logo
										style="
											max-height: 180px;
											margin-top: -10vw;
										"
									></Logo>

									<h2
										class="white--text font-weight-medium"
										style="z-index: 2"
									>
										Summit starts in:
									</h2>
									<lh-countdown
										time="2022-01-24T17:00:00Z"
										large
										value-class="white--text countdown-value"
										unit-class="white--text overline pt-2"
										style="z-index: 2"
										:labels="{
											days: 'DAYS',
											hours: 'HOURS',
											minutes: 'MINUTES',
											seconds: 'SECONDS',
										}"
									></lh-countdown></div
							></template>

							<!-- Live -->
							<template v-if="event.eventcdn.state === 'LIVE'">
								<div>
									<lh-video
										ref="video"
										live
										:src="
											event.eventcdn.stream_endpoint || ''
										"
										:autoplay="false"
										:muted="false"
										splash="https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4551pandora_poster.png"
									></lh-video>

									<div class="pt-4">
										<QAForm :event="event"></QAForm>
									</div>
								</div>
							</template>

							<!-- Ondemand -->
							<template
								v-if="event.eventcdn.state === 'ONDEMAND'"
							>
								<lh-video
									ref="video"
									:src="ondemand_video_url"
									:autoplay="false"
									:muted="false"
									splash="https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4551pandora_poster.png"
								></lh-video>
							</template>
						</v-responsive>
					</div>
				</v-col>

				<v-col cols="12" xl="3" class="pt-0">
					<v-container
						class="glass pa-12"
						:style="{
							'max-height': speakersMaxHeight,
							'overflow-y': 'auto',
						}"
					>
						<v-row>
							<v-col>
								<h2 class="white--text">Speakers</h2>
							</v-col>
						</v-row>
						<v-row class="white--text">
							<v-col
								class="d-flex flex-column py-8 py-xl-6"
								:cols="speakerCols"
								v-for="(speaker, speakerIdx) in speakers"
								:key="speakerIdx"
							>
								<SpeakerCard
									class="flex-fill"
									:speaker="speaker"
								></SpeakerCard>

								<v-divider
									class="mt-4"
									color="#A78E8D"
									style="border-width: 1.5px"
								></v-divider>
							</v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-container>
	</Page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SPEAKERS from "@/assets/data/speakers.data.js";
import SpeakerCard from "@/components/SpeakerCard.vue";
import QAForm from "@/components/QAForm.vue";

export default {
	name: "Home",

	data() {
		return {
			intervalId: null,
			videoProps: {
				loop: false,
				muted: false,
				autoplay: false,
				playsinline: true,
			},
			isPlaying: false,

			speakers: SPEAKERS,

			beacon: {
				userActivityInterval: null,
			},

			speakersMaxHeight: null,

			ondemand_video_url:
				"https://vod.eventcdn.net/pandora/Pandora_20220124.mp4",
		};
	},

	components: {
		SpeakerCard,
		QAForm,
	},

	mounted() {
		this.intervalId = setInterval(this.updateVideoData, 30000);

		this.updateSpeakersMaxHeight();
	},

	beforeDesroy() {
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}

		if (this.beacon.userActivityInterval) {
			clearInterval(this.beacon.userActivityInterval);
		}
	},

	computed: {
		...mapGetters(["me", "isAuthenticated", "beaconData"]),
		...mapState(["event", "videoData"]),

		speakerCols() {
			if (this.$vuetify.breakpoint.xl) return 12;

			if (this.$vuetify.breakpoint.lgAndUp) return 4;

			if (this.$vuetify.breakpoint.mdAndUp) return 6;

			return 12;
		},
	},

	methods: {
		setupBeacon() {
			// Setup heartbeat
			if (!this.beacon.userActivityInterval) {
				this.beacon.userActivityInterval = setInterval(() => {
					this.$livehouse.eventcdn.beacon.track("attending", {
						category: "Event",
						...this.beaconData,
						...this.videoData,
					});
				}, 30000);
			}
		},
		updateSpeakersMaxHeight() {
			if (!this.$vuetify.breakpoint.xl) {
				this.speakersMaxHeight = null;
				return;
			}

			console.log(
				"ContentWrapper:",
				this.$refs.contentWrapper,
				"Max Height:",
				this.$refs.contentWrapper.clientHeight
			);

			const wrapper = this.$refs?.video?.$el || this.$refs.contentWrapper;

			this.speakersMaxHeight = `${wrapper.clientHeight}px`;
		},
		updateVideoData() {
			console.log("Video interval - video is:", this.$refs.video);

			try {
				const currentTime = this.$refs.video.player.currentTime();

				const muted = this.$refs.video.player.muted();

				this.$store.commit("SET_VIDEO_DATA", {
					current_time: currentTime,
					muted,
				});
			} catch (err) {
				// Error
				console.error("Couldn't update video data");
			}
		},
	},

	watch: {
		isAuthenticated: {
			immediate: true,
			handler(isAuthenticated) {
				if (isAuthenticated) {
					this.setupBeacon();
				}
			},
		},
		event: {
			deep: true,
			handler(event) {
				if (event) {
					this.$nextTick(this.updateSpeakersMaxHeight);
				}
			},
		},
	},
};
</script>

<style lang="scss">
.v-application.livehouse-app {
	@media screen and (min-width: 1440px) {
		.countdown-value {
			font-size: 8.125rem !important;
			line-height: 8.125rem !important;
		}
	}

	.countdown-value {
		font-family: "Pan Text" !important;
		font-weight: normal !important;
		color: #f4eeeb !important;
		letter-spacing: 5.2px !important;
	}
}
</style>
